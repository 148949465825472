<template>
  <el-dialog :visible.sync="visible" title="详情" custom-class="dialog-warpper">
    <el-form :model="dataForm" :rules="dataRule" ref="dataFormDom">
      <el-card>
        <el-descriptions :column="2" border>
          <el-descriptions-item label="值班人员名字">
            <template slot="label">
              <!-- <span class="requireSyb">*</span> -->
              值班人员名字
            </template>

            <el-form-item prop="name">
              <el-input
                v-model="dataForm.name"
                placeholder=""
                clearable
                :disabled="true"
              ></el-input>
            </el-form-item>
          </el-descriptions-item>
          <!-- <el-descriptions-item label="班次">
            <template slot="label">
              <span class="requireSyb">*</span>
              班次
            </template>

            <el-form-item prop="banci">
              <el-input
                v-model="dataForm.banci"
                placeholder=""
                clearable
              ></el-input>
            </el-form-item>
          </el-descriptions-item>

          <el-descriptions-item label="人数">
            <template slot="label">
              <span class="requireSyb">*</span>
              人数
            </template>

            <el-form-item prop="renshu">
              <el-input
                v-model="dataForm.renshu"
                placeholder=""
                clearable
              ></el-input>
            </el-form-item>
          </el-descriptions-item> -->

          <el-descriptions-item label="地点">
            <template slot="label">
              <!-- <span class="requireSyb">*</span> -->
              地点
            </template>

            <el-form-item prop="didian">
              <el-select
                v-model="dataForm.didian"
                clearable
                filterable
                style="width: 100%"
                :disabled="true"
              >
                <el-option
                  v-for="item in typeOpt"
                  :key="item.id"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-descriptions-item>

          <!-- <el-descriptions-item label="事件详情">
            <template slot="label">
              <span class="requireSyb">*</span>
              事件详情
            </template>

            <el-form-item prop="event">
              <el-input
                v-model="dataForm.event"
                placeholder=""
                type="textarea"
                :rows="3"
              ></el-input>
            </el-form-item>
          </el-descriptions-item> -->
        </el-descriptions>
      </el-card>

      <el-card style="margin-top: 10px">
        <div slot="header" class="clearfix">
          <span>值班日志</span>
        </div>

        <el-form-item prop="dutyLog">
          <!-- <editor ref="editor1" v-model="dataForm.dutyLog"></editor> -->
          <div v-html="dataForm.dutyLog"></div>
        </el-form-item>
      </el-card>
    </el-form>

    <div slot="footer">
      <el-button @click="visible = false">关闭</el-button>
      <!-- <el-button type="primary" @click="updateHandler">修改</el-button> -->
    </div>
  </el-dialog>
</template>

<script>
import Editor from "@/components/editor.vue";
export default {
  components: {
    Editor,
  },
  data() {
    return {
      visible: false,
      dataForm: {
        name: "",
        // banci: "",
        // renshu: "",
        // event: "",
        didian: "",
        dutyLog: "",
      },
      dataRule: {
        name: [
          { required: true, message: "值班人员名字不能为空", trigger: "blur" },
        ],
        didian: [{ required: true, message: "地点不能为空", trigger: "blur" }],
        dutyLog: [
          { required: true, message: "值班日志不能为空", trigger: "blur" },
        ],
        // banci: [{ required: true, message: "班次不能为空", trigger: "blur" }],
        // renshu: [{ required: true, message: "人数不能为空", trigger: "blur" }],
        // event: [
        //   { required: true, message: "事件详情不能为空", trigger: "blur" },
        // ],
      },
      typeOpt: [
        { id: 1, label: "首都机场", value: "0" },
        { id: 2, label: "大兴机场", value: "1" },
      ],
    };
  },
  methods: {
    init(id) {
      this.dataForm = {
        name: "",
        didian: "",
        dutyLog: "",
        // banci: "",
        // renshu: "",
        // event: "",
      };
      this.visible = true;
      if (id) {
        this.dataForm.id = id;
        this.getDutylogInfo();
      } else {
        this.$refs.editor1.destroy();
        this.$refs.editor1.init(this.dataForm.dutyLog);
      }
    },
    getDutylogInfo() {
      this.$http.dutylog.info({ id: this.dataForm.id }).then((res) => {
        if (res.data) {
          this.dataForm = res.data;

          // this.$refs.editor1.destroy();
          // this.$refs.editor1.init(res.data.didian);

          this.$refs.editor1.destroy();
          this.$refs.editor1.init(res.data.dutyLog);
        }
      });
    },

    // 修改
    updateHandler() {
      const _this = this;

      this.$refs["dataFormDom"].validate((valid) => {
        if (valid) {
          this.$http.dutylog.update(this.dataForm).then((res) => {
            this.$message({
              message: res.msg,
              type: "success",
              duration: 1000,
              onClose() {
                _this.visible = false;
                _this.$emit("refreshDataList");
              },
            });
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
:deep() {
  .el-descriptions {
    .el-descriptions-item__label {
      width: 120px;
      text-align: center;
      color: #606266;
    }

    .el-descriptions-item__content {
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }

  .el-select {
    width: 100%;
  }

  .el-date-editor {
    width: 100%;
  }

  .el-form-item {
    margin-bottom: 0;
  }
}
</style>